<template>
  <div>
    <!-- 头部位置 -->
    <el-row class="header" type="flex" justify="start" align="middle">
      <el-col :span="3">/专利检测报告管理/专利检测报告列表</el-col>
    </el-row>
    <!-- 表格筛选位置 -->
    <el-row class="filter" type="flex" justify="space-between">
      <el-col :span="6">
        <span>筛选条件: </span>
        <el-select popper-class="select" v-model="page.id" placeholder="按报告类型显示">
          <el-option v-for="item in type
       " :key="item.id" :label="item.title" :value="item.id">
          </el-option>
        </el-select>

        <button class="btnFilter" @click="filterReport">筛选一下</button>
      </el-col>
      <el-col :span="4">
        <button class="updateBtn" @click="addReport">添加报告</button>
      </el-col>
    </el-row>
    <div class="box-card">
      <el-table :data="list">
        <el-table-column width="350" prop="mold" align="center" label="报告类型"></el-table-column>
        <el-table-column width="150" prop="certificate" label="专利检测报告证书号" align="center"></el-table-column>
        <el-table-column width="350" prop="name" label="检测报告名称" align="center">

        </el-table-column>
        <el-table-column width="250" prop="picture" label="展示图" align="center">
          <template slot-scope="{row}">
            <img class="slimg" :src="row.picture" alt="">
          </template>
        </el-table-column>
        <el-table-column width="300" prop="newStatus" label="编辑" align="center">
          <template slot-scope="{row}">
            <span class="edit" @click="editReport(row.id)">编辑</span>
          </template>
        </el-table-column>
        <el-table-column width="200" label="操作" align="center">
          <template slot-scope="{row}">
            <span class="del" @click="delReport(row.id)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加的弹框 -->
    <el-dialog class="dialog" @close="closeDialog" :visible.sync="showAddDialog" :title="reportName">
      <el-form :model="formData" ref="diaform" :rules="rules" label-width="250px" label-position="right">
        <el-form-item prop="mold" label="报告类型:">
          <!-- 单选框 -->
          <el-radio-group v-model="formData.mold" @change="radioChange">
            <el-radio v-for="item in type" :key="item.id" :label="item.title" name="type"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="certificate" label="专利检测报告证书号:">
          <el-input v-model="formData.certificate" placeholder="请填写专利报告证书号"></el-input>
        </el-form-item>
        <el-form-item prop="name" label="检测报告名称:">
          <el-input v-model="formData.name" placeholder="请填写检测报告名称"></el-input>
        </el-form-item>
        <!--展示图  -->
        <el-form-item prop="picture" label="展示图:">
          <el-upload accept=".png,.jpg" class="upload-demo1" action="http://192.168.8.29:8084/system/file/uploadFiles"
            :on-success="uploadSuccess" :before-upload="beforeAvatarUpload" multiple name="file" ref="upload" :limit="1"
            show-file-list>
            <img src="~@/assets/2.png" alt="">

          </el-upload>
        </el-form-item>
        <el-form-item prop="piiic" class="upload-report" label="检测报告文件:">
          <el-upload accept=".pdf" class="upload-demo1" action="http://192.168.8.29:8084/system/file/uploadFiles"
            :limit="1" :on-success="upload" multiple name="file" ref="upload" show-file-list>
            <img src="~@/assets/3.png" alt="">

          </el-upload>

        </el-form-item>
        <el-form-item class="btnitem">
          <el-button class="btnok" @click="saveReport">保存</el-button>
          <el-button class="btnout" @click="btnout">取消退出</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 页码 -->
    <el-row class="page" type="flex" justify="center">
      <el-pagination background layout="prev, pager, next,total" :total="count" :page-size="page.pageSize"
        :current-page.sync="page.pageNum" @current-change="handleGetList">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>

import { getReportList, addReport, editReport, delReport, getReportType, getReportById } from '@/api/report.js'
export default {
  data () {
    return {
      list: [],
      page: {
        pageNum: 1,
        pageSize: 2,

        id: null,
        flag: null
      },
      count: null,
      formData: {
        certificate: "",//证书号
        content: "",//报告类型描述
        mold: "",//报告类型
        moldId: 0,//类型id
        name: "",//报告名称
        picture: "",//展示图
        piiic: "",//检测报告长图
        id: null
      },
      type: [],
      reportName: '',
      showAddDialog: false,
      rules: {
        mold: [{ required: true, message: '报告类型不能为空', trigger: 'change' },],
        certificate: [{ required: true, message: '证书号不能为空', trigger: 'blur' },],
        name: [{ required: true, message: '报告名称不能为空', trigger: 'blur' },],
        picture: [{ required: true, message: '展示图不能为空', trigger: 'change' },],
        piiic: [{ required: true, message: '报告文件不能为空', trigger: 'change' },],
      }
    }
  },
  created () {
    this.getReportList()
    this.getReportType()
  },
  methods: {



    //获取报告列表
    async getReportList () {
      let { data: { obj, count } } = await getReportList(this.page)
      this.list = obj
      this.count = count
    },

    //根据报告类型筛选报告
    filterReport () {
      this.getReportList()
    },
    async getReportType () {
      let { data: { obj } } = await getReportType()
      this.type = obj
    },
    //添加新报告
    addReport () {
      this.reportName = '添加报告'

      this.showAddDialog = true

    },
    //添加新报告
    async saveReport () {
      await this.$refs.diaform.validate()
      if (this.reportName === '编辑报告') {
        await editReport(this.formData)
        this.$message.success('报告编辑成功')
      } else {
        await addReport(this.formData)
        this.$message.success('报告添加成功')
      }

      this.getReportList()
      this.showAddDialog = false
      this.formData.certificate = ''
      this.formData.mold = ''
      this.formData.piiic = ''
      this.formData.picture = ''
      this.formData.name = ''
      this.formData.content = ''
      this.formData.id = ''

    },
    //删除报告
    async delReport (id) {
      await delReport({ reportId: id + '' })
      this.getReportList()

    },
    //本地文件上传成功时-展示图

    uploadSuccess (file) {
      this.formData.picture = file.data
    },
    //本地文件上传成功时-检测报告文件

    upload (file) {
      this.formData.piiic = file.data
    },
    //关闭弹窗x
    closeDialog () {
      this.$refs.diaform.resetFields()
      this.formData.certificate = ''
      this.formData.mold = ''
      this.formData.name = ''
    },
    //点击编辑报告
    async editReport (id) {
      this.reportName = '编辑报告'
      let { data } = await getReportById({ reportId: id })
      this.formData.certificate = data.certificate
      this.formData.mold = data.mold
      this.formData.piiic = data.piiic
      this.formData.picture = data.picture
      this.formData.name = data.name
      this.formData.content = data.content
      this.formData.id = id
      this.showAddDialog = true
      //  let {data}=  await  editReport(id)
    },
    // 页码切换
    handleGetList () {

      this.getReportList(this.page)
    },
    // 退出按钮操作
    btnout () {
      this.showAddDialog = false
      this.closeDialog()

    },
    //报告类型变化时
    radioChange () {
      let flag = this.type.filter(item =>
        item.title === this.formData.mold
      )

      this.formData.moldId = flag[0].id

    }
  }

}


</script>
<style lang="less"  scoped>
// 头部样式
.header {
  height: 45px;
  background: #f9f9f9;
  .el-col {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4a71af;
  }
}
//头部筛选样式
.filter {
  margin-top: 43px;
  span {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #273352;
  }
  .el-select {
    margin-right: 20px;
  }
  /deep/ .el-input .el-input__inner {
    width: 155px;
    height: 40px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #c9c9c9;
  }
  .btnFilter {
    width: 110px;
    height: 40px;
    background: #4a71af;
    border-radius: 12px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border: none;
  }
  .updateBtn {
    width: 138px;
    height: 40px;
    background: #4a71af;
    border-radius: 12px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border: none;
    margin-right: 60px;
  }
}
//表格样式
.box-card {
  margin-top: 40px;
}
.el-table {
  margin-left: 20px;
  margin-right: 90px;
  .edit {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4a71af;
  }
  .del {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fe4040;
  }
}
/deep/ .el-table__header-wrapper {
  width: 1610px;
  height: 60px;
  line-height: 35px;
  background: #f2f2f2;
  border-radius: 20px 20px 0px 0px;

  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  th.el-table__cell {
    background: #f2f2f2;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
  }
}
/deep/ .el-table__cell {
  width: 48px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #4a4a4a;
  line-height: 17px;
}
.el-table::before {
  height: 0;
}
/deep/ .el-dialog {
  width: 1024px;
  height: 959px;
  background: #ffffff;
  border-radius: 51px;
  padding-top: 20px;
  .el-dialog__title {
    width: 76px;
    height: 22px;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #273352;
    line-height: 22px;
  }
  .el-dialog__close {
    font-size: 20px;

    color: #333303;
    opacity: 0.4;
    margin-top: 20px;
    margin-right: 13px;
  }
  .el-form {
    margin-top: 100px;
  }
  .el-form-item__label {
    width: 84px;
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #273352;
    line-height: 40px;
    letter-spacing: 1px;
  }
  .el-input {
    width: 70%;
    margin-left: -220px;
    margin-bottom: 10px;
    .el-input__inner {
      width: 620px;
      height: 40px;
      background: #ffffff;
      box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
      border-radius: 12px;
      border: 2px solid #4a71af;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4a71af;
      letter-spacing: 1px;
    }
  }
}
.btnitem&.el-form-item {
  margin-top: 100px;
  margin-right: 200px;
}
.btnok&.el-button {
  width: 110px;
  height: 40px;
  background: #4a71af;
  border-radius: 12px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 12px;
  margin-right: 60px;
}
.btnout&.el-button {
  width: 110px;
  height: 40px;
  background: #ffffff;
  border-radius: 12px;
  border: 2px solid #4a71af;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4a71af;
  line-height: 12px;
}
/deep/ .el-radio {
  .el-radio__label {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #273352;
  }
  .el-radio__inner {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #bcbcbc;
  }
}
/deep/ .el-radio-group {
  width: 100%;
  text-align: left;
}
/deep/ .is-checked .el-radio__inner {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4a71af !important;
  line-height: 20px;
}

.slimg {
  width: 60px;
  height: 85px;
}
.upload-demo1 {
  text-align: left;
}
/deep/ .el-upload-list__item {
  width: 50%;
  position: absolute;
  top: 0;
  left: 35%;
}
.page {
  margin-top: 50px;
}
</style>